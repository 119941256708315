import {
  Block,
  CardTeaser,
  Contact,
  Container,
  DotMap,
  Hero,
  Image,
  Layout,
  SectionSeparator,
  SectionTitle,
  SolutionsCarousel,
} from "components";
import type { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useEffect, useState } from "react";

import { WhyGENS } from "@components/WhyGENS/WhyGENS";
import { APITypes } from "@lib/api.types";
import API from "@lib/fetchData";
import { SupportedLocales } from "@lib/localization";
import { Paths } from "@lib/routing";
import { getLocalizedPath } from "@lib/routing/routing.util";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

interface SolutionsProps {
  solutions: APITypes.StrapiData<APITypes.Collections.Solution>[];
}

const DesktopSolutions = ({ solutions }: SolutionsProps) => {
  const { locale } = useRouter();
  const [activeSolution, setActiveSolution] = useState("");
  const { t } = useTranslation("common");

  useEffect(() => {
    const solutionBlocks: HTMLElement[] = Array.from(
      document.querySelectorAll("[data-solution-block]")
    );

    const watch = () => {
      let first = solutionBlocks[0];

      solutionBlocks.forEach((solutionBlock) => {
        if (solutionBlock.getBoundingClientRect().top <= 1) {
          first = solutionBlock;
        }
      });

      const slug = first.dataset.solutionBlock;
      if (slug && activeSolution !== slug) {
        setActiveSolution(slug);
      }

      requestAnimationFrame(watch);
    };

    requestAnimationFrame(watch);
  }, [activeSolution]);

  return (
    <div className="relative | flex items-start gap-8 lg:gap-12 xl:gap-16">
      <div className="sticky top-20 | flex-grow flex-shrink | pt-8 lg:pt-12">
        <h2 className="font-display text-3xl headline-2">
          {t("ourSolutions")}
        </h2>

        <div className="grid gap-4 lg:gap-6 | mt-8 lg:mt-12">
          {solutions.map((solution, index) => (
            <a
              key={index}
              className={`
                flex items-center
                font-bold lg:text-2xl
                ${
                  activeSolution === solution.attributes.slug
                    ? "text-brand-green"
                    : "opacity-50"
                }
                hover:text-black
                hover:opacity-100
                transition-all
              `}
              href={`#solution-${solution.attributes.slug}`}
            >
              {solution.attributes.icon ? (
                <div className="relative | flex-grow-0 flex-shrink-0 | w-7 h-7 lg:w-8 lg:h-8 | mr-3">
                  <Image
                    image={solution.attributes.icon.data?.attributes}
                    alt={solution.attributes.name}
                  />
                </div>
              ) : null}
              <span className="">{solution.attributes.name}</span>
            </a>
          ))}
        </div>
      </div>

      <div className="grid gap-8 lg:gap-12 xl:gap-16 | w-3/5 lg:w-[560px]">
        {solutions.map(({ attributes }) => {
          return attributes ? (
            <div key={attributes.slug}>
              <div
                className="-translate-y-8 lg:-translate-y-12"
                id={`solution-${attributes.slug}`}
                data-solution-block={attributes.slug}
              ></div>
              <CardTeaser
                title={attributes.name}
                description={attributes.description}
                buttonText={attributes.button_text}
                image={attributes.illustration.data?.attributes}
                animation="/animation.json"
                url={`${getLocalizedPath(
                  Paths.Solutions,
                  locale as SupportedLocales
                )}/${attributes.slug}`}
              />
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

interface HomePageProps {
  pageContent: APITypes.Pages.Home;
  solutions: APITypes.StrapiData<APITypes.Collections.Solution>[];
  mapLocations: APITypes.StrapiData<APITypes.Collections.MapLocation>[];
}

const Home = ({ pageContent, solutions, mapLocations }: HomePageProps) => {
  const { t } = useTranslation("common");

  return (
    <Layout translate={t} meta={pageContent.seo}>
      {pageContent.Hero ? <Hero hero={pageContent.Hero} /> : null}
      <Container>
        <Block width="sm">
          <div className="md:hidden | overflow-hidden">
            <SolutionsCarousel solutions={solutions} />
          </div>
          <div className="hidden md:block">
            <DesktopSolutions solutions={solutions} />
          </div>
        </Block>

        <SectionSeparator />

        <Block width="breakout">
          <div className="p-12 bg-gray-100 text-brand-night | breakout-block-cutout">
            <Block width="sm">
              <div className="lg:h-6" />

              <SectionTitle>{t("checkWorldwide")}</SectionTitle>

              <div className="h-12 lg:h-16" />

              <DotMap mapLocations={mapLocations} translate={t} />

              <div className="lg:h-6" />
            </Block>
          </div>
        </Block>

        <SectionSeparator />

        {pageContent.WhyGENS ? (
          <>
            <Block width="full">
              <WhyGENS {...pageContent.WhyGENS} />
            </Block>
            <SectionSeparator />
          </>
        ) : null}

        <Block width="breakout">
          {pageContent.Contact && <Contact contact={pageContent.Contact} />}
        </Block>
      </Container>
    </Layout>
  );
};

export default Home;

export const getStaticProps: GetStaticProps<Partial<HomePageProps>> = async ({
  locale,
  defaultLocale,
}) => {
  const localeToFetch = (locale ?? defaultLocale) as SupportedLocales;
  const api = new API(localeToFetch);

  const [{ data: page }, { data: solutions }, { data: MapLocations }] =
    await Promise.all([
      api.fetchHomePage(),
      api.fetchAllSolutions(),
      api.fetchAllMapLocations(),
    ]);

  if (!page) return { notFound: true };
  return {
    props: {
      ...(await serverSideTranslations(localeToFetch, ["common"])),
      pageContent: page.attributes,
      mapLocations: MapLocations,
      solutions: solutions.sort((a, b) => {
        const first = a.attributes ? a.attributes.display_order ?? 0 : 0;
        const second = b.attributes ? b.attributes.display_order ?? 0 : 0;
        return first - second;
      }),
    },
  };
};
